import React from "react";
import { v4 as uuidv4 } from 'uuid';
import { buttonize, moveColumn } from './func1'

export function Columns6(studentAll, field) {
    let students = studentAll.filter(s => s.currentStudent && s[field])

    return <div>
        <div className='noPrint' style={{ color: 'red', fontSize: '2em' }}>showScreen.js</div>
        {<><p id="error"></p>
            <table>
                <thead>
                    <tr>
                        <th className='num'></th>
                        <th className='name'>이름</th>
                        <th className='header1'>내용1</th>
                        <th className='header1'>자유곡</th>
                        <th className='header1'>내용3</th>
                        <th className='hanon'>하농</th>
                        <th className='header1'>반주</th>
                        <th className='header1'>내용6</th>
                    </tr>
                </thead>
                <tbody>
                    {students.map((row, idx) => {

                        let row1 = []
                        let tag = {}
                        if (row[field] !== null) row1 = row[field].split('\n')
                        row1 = moveColumn(row1)
                        var new_rows = row1[5].split(';')
                        if ((typeof new_rows[0] === 'string' && new_rows[0] !== '') &&
                            (typeof new_rows[1] === 'string' && new_rows[1] !== '')) {
                            tag = <>{buttonize(new_rows[0])}{
                                buttonize(new_rows[1])}</>
                        }
                        else if (typeof new_rows[0] === 'string' && new_rows[0] !== '') { tag = buttonize(new_rows[0]) }
                        else tag = null
                        return (
                            <tr key={uuidv4()}>
                                <td>{idx + 1}</td>
                                <td>{row.name}</td>
                                <td>{buttonize(row1[0])}</td>
                                <td>{buttonize(row1[1])}</td>
                                <td>{buttonize(row1[2])}</td>
                                <td className='hanon'>{buttonize(row1[3])}</td>
                                <td>{buttonize(row1[4])}</td>
                                <td>{

                                    tag

                                }</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </>
        }
    </div >
}


