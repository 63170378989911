import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { render } from "react-dom";
import { v4 as uuidv4 } from 'uuid';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";
import "./index.css";
import { Columns6 } from './showScreen'
import { ShowScreen1 } from './showScreen1'
import { ShowScreen2 } from './showScreen2'
import { ShowScreen3 } from './showScreen3'
import { Column5 as Columns5 } from './showScreen4'
import { Text6Columns } from './showScreen5'

const client = new ApolloClient({
  uri: "https://gma-orig-server1.herokuapp.com/graphql",
  // uri: "http://localhost:3900/graphql",
  cache: new InMemoryCache()
});

function Show({ kind, field }) {
  const { loading, error, data } = useQuery(gql`
  {
    studentAll {
    name
    pJin
    theory
    pJinOld
    theoryOld
    eval
    evalOld
    letter
    letterOld
    currentStudent
    currentPrint6Chulbu
    otherBan
    }
  }
`);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;
  let studentAll = data.studentAll.filter(s => s.currentPrint6Chulbu === true)
  studentAll = data.studentAll.filter(s => s.otherBan !== '바만')
  switch (kind) {
    case kinds[0]: return ShowScreen1(studentAll, field)
    case kinds[1]: return Columns5(studentAll, field)
    case kinds[2]: return Text6Columns(studentAll, field)
    case kinds[3]: return Columns6(studentAll, field)
    case kinds[4]: return ShowScreen3(studentAll, field)
    case kinds[5]: return ShowScreen2(studentAll, field)
    default: return <></>
  }
}

const kinds = ['table', '5 columns', 'text 6 columns', '6 columns', 'buttons', 'push']
const fields = ['pJin', 'theory']

function App() {
  const [kind, setKind] = useState('table')
  const [field, setField] = useState('pJin')
  return (
    <ApolloProvider client={client}>
      <div>
        <div className='noPrint' >
          <span className='green'>
            {fields.map((k, idx) => (
              <button
                key={uuidv4()}
                className={k === field ? 'yellow' : 'gray'}
                onClick={() => setField(fields[idx])}>
                {k}
              </button>))
            }
          </span>
          <span
            style={{ marginLeft: '20px' }}
            className='green'
          >
            {kinds.map((k, idx) => (
              <button
                key={uuidv4()}
                className={k === kind ? 'yellow' : 'gray'}
                onClick={() => setKind(kinds[idx])}>
                {k}
              </button>))
            }
          </span>
        </div>
        <div>
          <Show kind={kind} field={field} />
        </div>
      </div>
    </ApolloProvider >
  );
}

render(<App />, document.getElementById("root"));
