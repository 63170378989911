import React from "react";
import { v4 as uuidv4 } from 'uuid';

export function Column5(studentAll, field) {

    let students = studentAll.filter(s => s.currentStudent)

    return <div>
        <div className='noPrint' style={{ color: 'red', fontSize: '2em' }}>showScreen4.js</div>
        {
            <table>
                <thead>
                    <tr>
                        <th className='num'></th>
                        <th className='name'>이름</th>
                        <th className='header1'>내용1</th>
                        <th className='header1'>내용2</th>
                        <th className='header1'>내용3</th>
                        <th className='header1'>내용4</th>
                        <th className='header1'>내용5</th>
                    </tr>
                </thead>
                <tbody>
                    {students.map((row, idx) => {

                        let row1 = []

                        if (row[field] !== null) row1 = row[field].split('\n')


                        return (
                            <tr key={uuidv4()}>
                                <td>{idx + 1}</td>
                                <td>{row.name}</td>
                                <td>{row1[0]}</td>
                                <td>{row1[1]}</td>
                                <td>{row1[2]}</td>
                                <td>{row1[3]}</td>
                                <td>{row1[4]}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        }
    </div >
}


