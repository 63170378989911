import React from "react";
import { v4 as uuidv4 } from 'uuid';


export function ShowScreen1(studentAll, field) {

    let students = studentAll.filter(s => s.currentStudent)
    return <div>
        <div className='noPrint' style={{ color: 'red', fontSize: '2em' }}>showScreen1.js</div>
        {
            <table>
                <thead>
                    <tr>
                        <th className='num'></th>
                        <th className='name'>이름</th>
                        <th className='header1'>레슨내용</th>
                    </tr>
                </thead>
                <tbody>
                    {students.map((row, idx) =>
                    (
                        <tr key={uuidv4()}>
                            <td>{idx + 1}</td>
                            <td>{row.name}</td>
                            <td>{row[field]}</td>
                            {/* <td>{typeof row.pJin === 'string' ? row.pJin.replace('\n', '&#128512;') : row.pJin}</td> */}
                        </tr>
                    ))}
                </tbody>
            </table>
        }
    </div >
}


