import NewWindow from 'react-new-window'

export function split(item, by, no) {
    if (typeof item === 'undefined') return
    let result = []
    if (item === null || item === '') return
    else result = item.split(by)
    no = no + 1
    return { key: result[0] && result[0].trim(), value: result[1] && result[1].trim(), line: no }
}

export function getNumber(item) {
    let item1
    if (typeof item === 'undefined') return null
    try { item1 = parseInt(item) }
    catch (err) {
        return null
    }

    if (Number.isInteger(item1)) return item1
}

export function isKind(item, kind) {
    if (typeof item === 'undefined') return false
    if (item.includes(kind)) return true
    return false
}

export function isKindColor(item, place) {
    if (typeof item === 'undefined') return 'violet'
    if (typeof item === 'object') {
        //if (item.no === 'undefined')
    }
    for (let p of place) {
        if (item.includes(p.key)) return p.color
    }
    return 'violet'
}

export function correctPlace(item, place) {
    if (typeof item === 'undefined') return

    for (var i = 0; i < place.length; i++) {
        if (item.includes(place[i].key)) return place[i].place - 1
    }
    return 5 // 기타(그외) => place: 5
}

export function moveColumn(array) {
    if (typeof array === 'undefined') return
    const newArray = ['', '', '', '', '', '']

    for (let i = 0; i < array.length; i++) {
        if (typeof array[i] === 'undefined') continue
        let idx = correctPlace(array[i], place)
        if (newArray[idx] !== '') newArray[idx] = newArray[idx] + ';' + array[i]
        else newArray[idx] = array[i]
    }
    return newArray
}

export const place = [
    { key: '바이엘', place: 1, color: 'cyan' },
    { key: '체르니', place: 1, color: 'cyan' },
    { key: '연주곡', place: 2, color: 'crimson' },
    { key: '자유곡', place: 2, color: 'crimson' },
    { key: '동요', place: 3, color: 'green' },
    { key: '소곡집', place: 3, color: 'green' },
    { key: '소나티네', place: 3, color: 'green' },
    { key: '하농', place: 4, color: 'blue' },
    { key: '반주완성', place: 5, color: 'violet' },
    { key: 'K', place: 5, color: 'violet' },
]

export function buttonize(item) {
    let o = split(item, '-')
    if (typeof o === 'undefined') return
    let tag = <>
        {o.key && o.key !== '연주곡' && o.key !== '자유곡' && o.key !== '하농' &&
            < button
                className={isKindColor(o.key, place)}
                onClick={() => Demo(o.value)}>
                {o.key}
            </button>
        }
        {
            o.value &&
            <button>
                {o.value}
            </button>
        }
    </>

    return tag
}
export function buttonize1(item) {
    let o = split(item, '-')
    if (typeof o === 'undefined') return
    let tag = <>
        {o.key && o.key !== '연주곡' && o.key !== '자유곡' && o.key !== '하농' &&
            < span
                className={isKindColor(o.key, place)}
                onClick={() => Demo(o.value)}>
                {o.key}
            </span>
        }
        {o.key && o.key !== '연주곡' && o.key !== '자유곡' && o.key !== '하농' &&
            < span
                className={isKindColor(o.key, place)}
                onClick={() => Demo(o.value)}>
                -
            </span>
        }
        
        {
            o.value &&
            <span>
                {o.value}
            </span>
        }
    </>

    return tag
}


export function Demo(text) {
    return <NewWindow>
        <h1>Hi 👋</h1>
        <h1>text</h1>
    </NewWindow>
}

export function showNearNumbers1(item) {
    console.log('clicked on ', item)
    Demo('clicked on ' + item)
}

export function showNearNumbers(item) {
    let num = getNumber(item)
    return <>
        <button>{num - 4}</button>
        <button>{num - 3}</button>
        <button>{num - 2}</button>
        <button>{num - 1}</button>
        <button>{num}</button>
        <button>{num + 1}</button>
        <button>{num + 2}</button>
        <button>{num + 3}</button>
        <button>{num + 4}</button>
    </>
}