import React from "react";
import { v4 as uuidv4 } from 'uuid';
import { split, isKindColor, moveColumn, place } from './func1';

export function ShowScreen3(studentAll, field) {

    let students = studentAll.filter(s => s.currentStudent && s[field])
    let no = 0













    function buttonize(item) {
        let o = split(item, '-', no)
        if (typeof o === 'undefined') return
        let tag = <>
            {o.key &&
                <button
                    className={isKindColor(o.key, place)}
                >
                    {o.key}
                </button>
            }
            {o.value &&
                <button>
                    {o.value}
                </button>
            }
        </>

        return tag
    }


    return <div>
        <div className='noPrint' style={{ color: 'red', fontSize: '2em' }}>showScreen3.js</div>
        {<><p id="error"></p>
            <table>
                <thead>
                    <tr>
                        <th className='num'></th>
                        <th className='name'>이름</th>
                        <th className='header1'>내용1</th>
                        <th className='header1'>내용2</th>
                        <th className='header1'>내용3</th>
                        <th className='header1'>내용4</th>
                        <th className='header1'>내용5</th>
                    </tr>
                </thead>
                <tbody>
                    {students.map((row, idx) => {

                        let row1 = []

                        if (row[field] !== null) row1 = row[field].split('\n')
                        row1 = moveColumn(row1)

                        return (
                            <tr key={uuidv4()}>
                                <td>{idx + 1}</td>
                                <td>{row.name}</td>
                                <td>{buttonize(row1[0])}</td>
                                <td>{buttonize(row1[1])}</td>
                                <td>{buttonize(row1[2])}</td>
                                <td>{buttonize(row1[3])}</td>
                                <td>{buttonize(row1[4])}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </>
        }
    </div >
}

